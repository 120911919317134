import React, { useContext } from "react";
import backBtn from "../../img/back_btn.png";
import { AppContext } from "../../context";

const NavbarP = () => {
  const { config, screen, currentBlock } = useContext(AppContext);

  const switchBack = () => {
    
  }

  return (
    <div id="portNavbar" style={{ backgroundColor: config["colors"]["nav"] }}>
        <div id="tagAndArrow" style={{display: "flex", justifyContent:"space-between", width: "30%", marginLeft: "20px", alignItems: "center"}}>
        {/* {screen !== "pMenu"? <img id="backbtnLogo" alt="" src={backBtn} onClick={switchBack} style={{width: "30px"}}/> : ""} */}
            <h2 style={{color: "white", margin: "0px"}}>{currentBlock["name"] === "Form Module Portrait"? "Form fill up" : "360 Image"}</h2>
        </div>
      {config["logo"].length > 0 && <img id="logoP" alt="" src={config["logo"]} />}
    </div>
  );
};

export default NavbarP;
