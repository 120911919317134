import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../context";
import Button from "./Button";
import Rotate from "./Rotate";
import PdfThumbnail from "./PdfThumbnail";
import { addImageToList, pdfUpload } from "../context/utils";
import * as pdfjsLib from "pdfjs-dist";

let count = 0;

const ImageUpload = () => {
  const {
    setLandscape,
    setScreen,
    isLandscape,
    currentBlock,
    storedImage,
    setStoredImage,
    currentImage,
    setCurrentImage,
    setMandatory,
    setCondition,
    condition,
    mandatory,
    config,
    inspectionId,
    clientId,
  } = useContext(AppContext);

  const fileInputRef = useRef(null);
  const popupRef = useRef(null);
  const waringpopuup = useRef(null);

  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [errorMssg, setError] = useState("");
  const [typeOfUpload, setTypeOfUpload] = useState(
    Object.keys(config).includes("imageUpload")
      ? config["imageUpload"]["type"] === "pdf"
        ? "pdf"
        : "image"
      : "img"
  );
  window.onresize = () => {
    setLandscape(land());
    setStoredImage(storedImage);
  };

  const validatePdf = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      // If the PDF can be loaded without errors, it's valid
      if (pdf.numPages > 0) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("PDF validation error:", error);
      return false;
    }
  };

  const handleFiles = async (event) => {
    const files = event.target.files;
    const maxCount = currentBlock["count"];
    const remainingSlots = maxCount - storedImage.length;

    if (files.length > 0 && remainingSlots > 0) {
      const filesToUpload = Array.from(files).slice(0, remainingSlots);

      for (const file of filesToUpload) {
        if (typeOfUpload === "img") {
          if (file.type.startsWith("image/")) {
            // Handle image files
            const imageFile = await convertToJpegBase64(file);
            if (imageFile) {
              setStoredImage((prevImages) => [
                ...prevImages,
                { imgData: imageFile, id: count },
              ]);
              count += 1;
            }
          }
        }
        if (typeOfUpload === "pdf") {
          if (file.type === "application/pdf") {
            // Handle PDF files
            try {
              const isValidPdf = await validatePdf(file);
              if (isValidPdf) {
                const pdfData = await convertPdfToBase64(file);
                if (pdfData) {
                  setStoredImage((prevImages) => [
                    ...prevImages,
                    { pdfData, id: count },
                  ]);
                  count += 1;
                }
              } else {
                setError(
                  Object.keys(config).includes("imageUpload")
                    ? config["imageUpload"]["fileCorrupted"]
                    : "The file is corrupted or invalid."
                );
                setTimeout(() => {
                  setError("");
                }, 5000);
                console.log("The PDF file is corrupted or invalid.");
              }
            } catch (error) {
              setError(
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["fileCorrupted"]
                  : "The file is corrupted or invalid."
              );
              setTimeout(() => {
                setError("");
              }, 5000);
              console.log("Error processing PDF file:", error);
            }
          }
        }
      }
    }
  };

  const convertPdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const convertToJpegBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          //if (img.width < 1920 || img.height < 1080) {
          // if (img.width < 2000 && img.height < 2000) {   //sanchit sir ask to enter 2000 as a raw
          //   setError("The image resolution is too high. The image must be in landscape orientation and its resolution must be under 2000p.")
          //   setTimeout(()=>{setError("")},5000)
          //   resolve(false);
          //   return;
          // }

          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height);

          const jpegBase64 = canvas.toDataURL("image/jpeg", 1);
          resolve(jpegBase64);
        };

        img.onerror = () => {
          reject(new Error("Failed to load image"));
        };
      };

      reader.onerror = () => {
        reject(new Error("Failed to read file"));
      };
    });
  };

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  const openPopUp = (e) => {
    setCurrentImage(e);
    if (popupRef.current) {
      popupRef.current.style.display = "flex";
    }
  };

  const removeImage = () => {
    if (popupRef.current) {
      popupRef.current.style.display = "none";
    }
    setStoredImage((prevImages) =>
      prevImages.filter((item) => item.id !== currentImage.id)
    );
  };

  const finish = () => {
    storedImage.map((e) => {
      if (Object.keys(e).includes("imgData")) {
        const image = {
          name: `${currentBlock["tag"]}.jpg`,
          imgData: e.imgData,
          tag: currentBlock["tag"],
          web_region: null,
          angle: null,
          region: "eu",
          videoData: null,
          dialStatus: null,
        };
        addImageToList(image);
      } else {
        pdfUpload(clientId, inspectionId, e);
      }
    });

    if (currentBlock["mandatory"]) {
      let temp1 = mandatory;
      temp1[currentBlock["id"]] = "completed";
      setMandatory(temp1);
    }
    let temp = condition;
    temp[currentBlock["id"]] = "completed";
    setCondition(temp);
    setScreen("menu");
  };

  return (
    <>
      <div className="uploadMainDiv">
        {!isLandscape && (
          <div
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              width: "100dvw",
              height: "100dvh",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <Rotate />
          </div>
        )}
        <div className="ImageDiv">
          {storedImage.map((e) =>
            Object.keys(e).includes("imgData") ? (
              <img
                key={e.id}
                src={e.imgData}
                alt={`Uploaded ${e.id}`}
                style={{
                  height: "80px",
                  width: "130px",
                  margin: "10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => openPopUp(e)}
              />
            ) : (
              <div
                key={e.id}
                src={e.pdfData}
                alt={`Uploaded ${e.id}`}
                style={{
                  height: "80px",
                  width: "130px",
                  margin: "10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => openPopUp(e)}
              >
                <PdfThumbnail
                  base64Pdf={e.pdfData}
                  scale={0.1}
                  width={"130px"}
                  height={"80px"}
                />
              </div>
            )
          )}
        </div>
        <div className="errormssg">
          {errorMssg.length > 0 && (
            <p style={{ color: "red", fontWeight: "bold" }}>{errorMssg}</p>
          )}
        </div>
        <div className="buttonDiv">
          <Button
            secondaryBtn="true"
            text={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["backTxtBtn"]
                : "Back"
            }
            disabled={false}
            onclick={() => {
              setScreen("menu");
            }}
            marginDefault="0px 0px 0px 0px"
          />
          <Button
            text={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["addImageTxtBtn"]
                : "Add Images"
            }
            disabled={storedImage.length >= currentBlock["count"]}
            onclick={openFilePicker}
            marginDefault="0px 0px 0px 0px"
          />
          <Button
            text={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["uploadTxtBtn"]
                : "Upload"
            }
            disabled={storedImage.length === 0}
            onclick={finish}
            marginDefault="0px 0px 0px 0px"
          />
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFiles}
            accept={
              Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["type"] === "img"
                  ? "image/*"
                  : "application/pdf"
                : "image/*"
            }
            multiple
            style={{ display: "none" }}
            disabled={storedImage.length >= currentBlock["count"]}
          />
        </div>
        <div ref={popupRef} className="uploadPopup">
          {currentImage &&
            (Object.keys(currentImage).includes("imgData") ? (
              <img
                src={currentImage.imgData}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
                alt="Preview"
              />
            ) : (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                }}
                alt="Preview"
              >
                <PdfThumbnail
                  base64Pdf={currentImage.pdfData}
                  scale={1}
                  width={"100%"}
                  height={"100%"}
                />
              </div>
            ))}
          <div className="popupDiv">
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["backTxtBtn"]
                  : "Back"
              }
              disabled={false}
              onclick={() => {
                if (popupRef.current) popupRef.current.style.display = "none";
              }}
              marginDefault="0px 0px 0px 0px"
            />
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["removeTxtBtn"]
                  : "Remove"
              }
              disabled={false}
              onclick={removeImage}
              marginDefault="0px 0px 0px 0px"
            />
          </div>
        </div>
        <div ref={waringpopuup} className="waringpopuup">
          <div className="waringpopuupdiv">
            <p style={{ fontWeight: "bold", textAlign: "center" }}>
              {Object.keys(config).includes("imageUpload")
                ? config["imageUpload"]["warmingPopMssg"]
                : "You have to upload the front and back of the RC."}
            </p>
            <Button
              text={
                Object.keys(config).includes("imageUpload")
                  ? config["imageUpload"]["continueTxtBtn"]
                  : "Continue"
              }
              disabled={storedImage.length >= currentBlock["count"]}
              onclick={() => {
                waringpopuup.current.style.display = "none";
              }}
              marginDefault="0px 0px 0px 0px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
