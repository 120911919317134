import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context";
import {
  finalUpload,
  backupImagesStatus,
  totalImagesUploadStatus,
  userLogs,
  patLogs,
  setFeedBackStatus,
  setRating
} from "../../context/utils";
import successLoading from "../../img/successLoading.gif";
import one_star from "../../img/one_star.png";
import two_star from "../../img/two_star.png";
import three_star from "../../img/three_star.png";
import four_star from "../../img/four_star.png";
import five_star from "../../img/five_star.png";
let logsbugs = true;
var progressBar = require("progressbar.js");
let interval = null;
let d = 0;
let uploadButton = false;
let upValue = 1;
let sendRetryEvent = true;
const emojis = [
  { value: 1, src: one_star, alt: "Very Sad" },
  { value: 2, src: two_star, alt: "Sad" },
  { value: 3, src: three_star, alt: "Neutral" },
  { value: 4, src: four_star, alt: "Happy" },
  { value: 5, src: five_star, alt: "Very Happy" },
];

const SuccessPort = () => {
  const land = () => window.innerWidth > window.innerHeight * 1.2;
  const [screenHeight, setScreenHeight] = useState(land() ? false : true)
  const [barData, setBarData] = useState(0);
  const [commentTxt, setCommentTxt] = useState("");
  const [ratingValue, setRatingValue] = useState(0); // State to store the rating value
  const [innerHeight, setInnerHeight] = useState(`${window.innerHeight}px`); // State to store the rating value
  const [innerWeight, setInnerWeight] = useState(`${window.innerWeight}px`); // State to store the rating value
  let dRef = useRef(null);
  const pRef = useRef(null);
  const ratingRef = useRef();

  const {
    setErrorMessage,
    setScreen,
    stepCovered,
    setStepCovered,
    config,
    inspectionId,
    uploaded,
    setUploaded,
    clientId,
    showAlert,
    setCurrentPage,
    token
  } = useContext(AppContext);

  window.onresize = () => {
    setScreenHeight(land() ? false : true);
    setInnerHeight(`${window.innerHeight}px`);
    setInnerWeight(`${window.innerWidth}px`);
  };

  if (logsbugs) {
    patLogs("Entered to image Uploading page", inspectionId);
    userLogs({ position: 8, last_page: "uploading page", inspectionId });
  }
  logsbugs = false;

  const imageStatus = () => {
    const data = totalImagesUploadStatus();
    pRef.current.textContent = `${Object.keys(config["success-page"]).includes("ImageUploadingStatusTxt")
      ? config["success-page"]["ImageUploadingStatusTxt"]
      : "Images uploading"
      } ${data.uploadedImage + (upValue ? 0 : 1)} / ${data.totalImage + 1}`;
    setBarData(
      (data.uploadedImage + (upValue ? 0 : 1)) / (data.totalImage + 1)
    );
    sendUploadProgressIFrame(((data.uploadedImage + (upValue ? 0 : 1)) / (data.totalImage + 1)) * 100);
    if (upValue === 0) {
      clearInterval(interval);
      setUploaded(true);
      patLogs("All images uploaded, At success page", inspectionId);
      userLogs({ position: 8, last_page: "success page", inspectionId });
      setCurrentPage("Success Page");
    }
  };

  const sendUploadProgressIFrame = (percentage) => {
    console.log("PERCENTAGE: ", Math.floor(percentage));
    window.top.postMessage(`PERCENTAGE: ${Math.floor(percentage)}`, "*");
  };

  const sendMessageIFrame = (message) => {
    if (message === "uploading") {
      window.top.postMessage("uploading", "*");
    }
    else if (message === "retry upload") {
      window.top.postMessage("retry upload", "*");
    }
    else {
      window.top.postMessage("uploaded", "*");
    }
  };

  const startFinalUpload = async () => {
    if (!backupImagesStatus()) {
      let d = await finalUpload();
      if (d === "usedToken") {
        patLogs("used token", inspectionId);
        clearInterval(interval);
        setErrorMessage(
          "The link has already been used. Please contact your administrator for a new link"
        );
        setScreen("error");
      } else if (d) {
        console.log("INSPECTION_SUBMISSION_SUCCESS");
        upValue = 0;
        sendMessageIFrame();
      }
    } else if (uploadButton) {
      let b = await finalUpload();
      if (b === "usedToken") {
        patLogs("used token", inspectionId);
        clearInterval(interval);
        setErrorMessage(
          "The link has already been used. Please contact your administrator for a new link"
        );
        setScreen("error");
      } else if (d) {
        console.log("INSPECTION_SUBMISSION_SUCCESS");
        upValue = 0;
        sendMessageIFrame();
      }
    }
  };

  useEffect(() => {
    drawProgress().animate(barData);
  }, [barData]);

  const drawProgress = () => {
    dRef.current.innerHTML = null;
    let bar = new progressBar.Circle("#progress-final", {
      strokeWidth: 6,
      easing: "easeInOut",
      duration: 2000,
      color: "#000000",
      trailColor: "#eee",
      trailWidth: 4,
      svgStyle: null,
      step: function (state, circle) {
        let value = Math.round(circle.value() * 100);
        circle.setText(`${value}%`);
      },
    });
    barData && bar.set(d);
    d = barData;
    return bar;
  };


  useEffect(() => {
    interval = setInterval(() => {
      imageStatus();
      startFinalUpload();
      if (backupImagesStatus() && sendRetryEvent) {
        sendRetryEvent = false;
        sendMessageIFrame("retry upload");
      }
    }, 2000);
    setFeedBackStatus("");
    sendMessageIFrame("uploading");
  }, []);

  useEffect(() => {
    if (uploaded) {
      window.top.postMessage("uploaded", "*");
      if (Object.keys(config["success-page"]).includes("rating-popup")) {
        ratingRef.current.style.display = "flex";
      }
    }
  }, [uploaded]);

  const handleRatingSubmit = () => {
    // Handle the rating submission logic here
    setRating({
      feedback: ratingValue,
      comment: commentTxt,
      token: token
    })
    ratingRef.current.style.display = "none";
  };

  const newUrl = () => {
    window.location.href = "https://webapp-gen.inspektlabs.com/login";
  };

  const handleChange = (e) => {
    setCommentTxt(e.target.value); // Update state with the value of the text field
  };

  return uploaded ? (
    <div id="final-screen" className="screen" style={{ position: "relative", marginTop: "35%" }}>
      {config["success-page"]["image"].length > 0 && <img className="final-gif" src={config["success-page"]["image"]} style={{ width: screenHeight ? "40%" : "" }}></img>}
      <p style={{ fontSize: "x-large" }}>{config["success-page"]["text1"]}</p>
      <p style={{ fontSize: "x-large" }}>{config["success-page"]["text2"]}</p>
      <p style={{ fontSize: "x-large" }}>
        {config["success-page"]["support-email-text"]}{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {config["success-page"]["support-email"]}{" "}
        </span>
      </p>
      <br></br>
      <p style={{ fontSize: "x-large", fontWeight: "lighter" }}>
        {config["success-page"]["inspection-id"]}: {inspectionId}
      </p>
      {clientId === "kshitij_testing" && (
        <div className="modal-btn-container">
          <button
            className="btn btn-blue"
            style={{ backgroundColor: "rgb(0, 108, 202" }}
            onClick={() => newUrl()}
          >
            Web Gen
          </button>
        </div>
      )}

      {/* Rating Popup */}
      <div
        ref={ratingRef}
        className="rating-popup"
        style={{
          width: screenHeight ? innerWeight : "100vw",
          height: screenHeight ? innerHeight : "100vh",
        }}
      >
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: screenHeight ? "60vh" : "70vh",
          width: screenHeight ? "180vw" : "50vw",
          zIndex: 5,
          backgroundColor: "white",
          borderRadius: "10px"
        }}
        >
          <h3 style={{ margin: "0px", fontSize: screenHeight ? "xx-large" : "" }}>{config["success-page"]["rating-heading-txt"] ? config["success-page"]["rating-heading-txt"] : "Please rate your experience"}</h3>
          <div className="emoji-rating" style={{ width: screenHeight ? "70%" : "" }}>
            {emojis.map((emoji) => (
              <img
                key={emoji.value}
                src={emoji.src}
                alt={emoji.alt}
                onClick={() => setRatingValue(emoji.value)}
                style={{
                  cursor: "pointer",
                  opacity: ratingValue === emoji.value ? 1 : 0.5,
                  height: screenHeight ? "80px" : "",
                  width: screenHeight ? "80px" : ""
                }}
              />
            ))}
          </div>
          <input
            type="text"
            value={commentTxt}
            onChange={handleChange}
            placeholder={config["success-page"]["rating-comment-txt"] ? config["success-page"]["rating-comment-txt"] : "Comment Here.."}
            style={{ padding: "6px", width: "80%", height: screenHeight ? "70px" : "40px", margin: "0px", fontSize: screenHeight ? "xx-large" : "" }}
          />
          <button
            type="submit"
            disabled={ratingValue === 0}
            onClick={handleRatingSubmit}
            style={{
              width: screenHeight ? "300px" : "200px",
              lineHeight: "40px",
              border: "2px solid transparent",
              color: "white",
              backgroundColor: config["colors"]["btn"],
              opacity: [ratingValue === 0 ? .4 : 1],
              fontSize: screenHeight ? "xx-large" : "",
              padding: '5px'
            }}
          >
            {config["success-page"]["rating-btn-text"] ? config["success-page"]["rating-btn-text"] : "Submit"}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div id="final-screen" className="screen">
      <div ref={dRef} className="progress-final" id="progress-final"></div>
      <div
        style={{
          justifyContent: "space-around",
          alignItems: "center",
          width: "270px",
          display: "flex",
        }}
      >
        <p ref={pRef}></p>
        <img src={successLoading}></img>
      </div>
      <h4>{config["success-page"]["uploading-text"]}</h4>
      <p>{config["success-page"]["tab-text"]}</p>
      <div className="InternetWarning">
        <p>{showAlert && showAlert}</p>
      </div>
      {backupImagesStatus() && (
        <div className="modal-btn-container">
          <button
            className="btn btn-blue"
            style={{ backgroundColor: "rgb(0, 108, 202" }}
            onClick={() => (uploadButton = true)}
          >
            Retry Uploading
          </button>
        </div>
      )}
    </div>
  );
};


export default SuccessPort;
