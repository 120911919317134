import * as constants from "../utils_new/Constants";

export class Nudges {
  constructor() {
    this.orientatedParts = [
      "fender",
      "qtr_panel",
      "window_glass",
      "front_door",
      "back_door",
      "running_board",
    ];
  }

  /**
   * Function to determine the nudge direction based on single frame detection.
   * @param {Object} partCoveredMap - A dictionary representing parts covered in different regions.
   * @param {string} currentRegion - The current region to check for nudge.
   * @param {Array} boundingBoxDetections - Array of detected bounding boxes with labels.
   * @returns {Object} - The nudge direction ('UP', 'DOWN', or 'NA') and the part to point to.
   */
  getNudgeFromSingleFrameDetection(
    partCoveredMap,
    currentRegion,
    boundingBoxDetections
  ) {
    let nudge = "NA";
    let partToPoint = "";
    const detectedPartList = this.getDetectedPartList(
      currentRegion,
      boundingBoxDetections
    );
    //console.log(detectedPartList, "in main function");
    if (currentRegion === constants.REGIONS.FRONT) {
      ({ nudge, partToPoint } = this.handleFrontRegion(
        detectedPartList,
        partCoveredMap[currentRegion]
      ));
    } else if (currentRegion === constants.REGIONS.REAR) {
      ({ nudge, partToPoint } = this.handleRearRegion(
        detectedPartList,
        partCoveredMap[currentRegion]
      ));
    } else if (currentRegion === constants.REGIONS.SIDE_LEFT) {
      ({ nudge, partToPoint } = this.handleSideRegion(
        detectedPartList,
        partCoveredMap,
        currentRegion,
        "left"
      ));
    } else if (currentRegion === constants.REGIONS.SIDE_RIGHT) {
      ({ nudge, partToPoint } = this.handleSideRegion(
        detectedPartList,
        partCoveredMap,
        currentRegion,
        "right"
      ));
    }

    return { partToPoint, Nudge: nudge };
  }

  /**
   * Helper function to get detected part list based on the current region and bounding box detections.
   * @param {string} currentRegion - The current region.
   * @param {Array} boundingBoxDetections - Array of detected bounding boxes with labels.
   * @returns {Array} - List of detected parts.
   */
  getDetectedPartList(currentRegion, boundingBoxDetections) {
    return boundingBoxDetections.reduce((detectedPartList, detection) => {
      const { label } = detection;
      if (
        currentRegion === constants.REGIONS.SIDE_LEFT &&
        this.orientatedParts.includes(label)
      ) {
        detectedPartList.push(`left_${label}`);
      } else if (
        currentRegion === constants.REGIONS.SIDE_RIGHT &&
        this.orientatedParts.includes(label)
      ) {
        detectedPartList.push(`right_${label}`);
      } else if (
        currentRegion === constants.REGIONS.FRONT ||
        currentRegion === constants.REGIONS.REAR
      ) {
        detectedPartList.push(label);
      }
      return detectedPartList;
    }, []);
  }

  handleFrontRegion(detectedPartList, partCoveredMap) {
    //console.log("handleFrontRegion", detectedPartList, detectedPartList);
    if (detectedPartList.includes("hood")) {
      if (!partCoveredMap.has("front_glass")) {
        return { nudge: "UP", partToPoint: "Front Glass" };
      } else if (!partCoveredMap.has("front_bumper")) {
        return { nudge: "DOWN", partToPoint: "Front Bumper" };
      }
    } else if (detectedPartList.includes("front_bumper")) {
      if (!partCoveredMap.has("hood")) {
        return { nudge: "UP", partToPoint: "Hood" };
      } else if (!partCoveredMap.has("front_glass")) {
        return { nudge: "UP", partToPoint: "Front Glass" };
      }
    } else if (detectedPartList.includes("front_glass")) {
      if (!partCoveredMap.has("hood")) {
        return { nudge: "DOWN", partToPoint: "Hood" };
      } else if (!partCoveredMap.has("front_bumper")) {
        return { nudge: "DOWN", partToPoint: "Front Bumper" };
      }
    }
    return { nudge: "NA", partToPoint: "" };
  }

  handleRearRegion(detectedPartList, partCoveredMap) {
    //console.log("handleRearRegion", detectedPartList, detectedPartList);
    if (detectedPartList.includes("dicky")) {
      if (!partCoveredMap.has("back_glass")) {
        return { nudge: "UP", partToPoint: "Rear Glass" };
      } else if (!partCoveredMap.has("back_bumper")) {
        return { nudge: "DOWN", partToPoint: "Back Bumper" };
      }
    } else if (detectedPartList.includes("back_bumper")) {
      if (!partCoveredMap.has("dicky")) {
        return { nudge: "UP", partToPoint: "Tail Gate" };
      } else if (!partCoveredMap.has("back_glass")) {
        return { nudge: "UP", partToPoint: "Rear Glass" };
      }
    } else if (detectedPartList.includes("back_glass")) {
      if (!partCoveredMap.has("dicky")) {
        return { nudge: "DOWN", partToPoint: "Tail Gate" };
      } else if (!partCoveredMap.has("back_bumper")) {
        return { nudge: "DOWN", partToPoint: "Back Bumper" };
      }
    }
    return { nudge: "NA", partToPoint: "" };
  }

  handleSideRegion(detectedPartList, partCoveredMap, currentRegion, side) {
   // console.log("handleSideRegion", detectedPartList, detectedPartList, side);
    const oppositeRegion = `Rear-Isometric-${
      side.charAt(0).toUpperCase() + side.slice(1)
    }`;
    const sidePrefix = `${side}_`;

    if (
      detectedPartList.includes(`${sidePrefix}front_door`) ||
      detectedPartList.includes(`${sidePrefix}back_door`)
    ) {
      if (!partCoveredMap[currentRegion].has(`${sidePrefix}window_glass`)) {
        return { nudge: "UP", partToPoint: "Window Glass" };
      } else if (
        !partCoveredMap[currentRegion].has(`${sidePrefix}running_board`)
      ) {
        return { nudge: "DOWN", partToPoint: "Running Board" };
      }
    } else if (detectedPartList.includes(`${sidePrefix}running_board`)) {
      if (!partCoveredMap[currentRegion].has(`${sidePrefix}front_door`)) {
        return { nudge: "UP", partToPoint: "Doors" };
      } else if (
        !partCoveredMap[currentRegion].has(`${sidePrefix}back_door`) ||
        !partCoveredMap[oppositeRegion].has(`${sidePrefix}qtr_panel`)
      ) {
        return { nudge: "UP", partToPoint: "Doors" };
      }
    } else if (detectedPartList.includes(`${sidePrefix}window_glass`)) {
      if (!partCoveredMap[currentRegion].has(`${sidePrefix}front_door`)) {
        return { nudge: "DOWN", partToPoint: "Doors" };
      } else if (
        !partCoveredMap[currentRegion].has(`${sidePrefix}back_door`) ||
        !partCoveredMap[oppositeRegion].has(`${sidePrefix}qtr_panel`)
      ) {
        return { nudge: "DOWN", partToPoint: "Doors" };
      }
    }

    return { nudge: "NA", partToPoint: "" };
  }
}
