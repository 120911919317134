export const CLASS_LABELS = {//0: 'BG',    //Because BG is the default class and not actually a car part
    1: 'back_bumper',
    2: 'back_door',
    3: 'back_glass',
    4: 'dicky',
    5: 'fender',
    6: 'front_bumper',
    7: 'front_door',
    8: 'front_glass',
    9: 'headlight',
    10: 'hood',
    11: 'qtr_panel',
    12: 'running_board',
    13: 'side_view_mirror',
    14: 'taillight',
    15: 'wheel',
    16: 'window_glass'
}

export const REGIONS = {
    FRONT: "Front",
    FRONT_ISOMETRIC_LEFT: "Front-Isometric-Left",
    FRONT_ISOMETRIC_RIGHT: "Front-Isometric-Right",
    SIDE_LEFT: "Side-Left",
    SIDE_RIGHT: "Side-Right",
    REAR: "Rear",
    REAR_ISOMETRIC_RIGHT: "Rear-Isometric-Right",
    REAR_ISOMETRIC_LEFT: "Rear-Isometric-Left",
}

export const REGION_COVERAGE_STATUS = {
    NOT_COVERED: 0,
    PARTIALLY_COVERED: 1,
    FULLY_COVERED: 2,
}
export const MAX_NUMBER_OF_FRAME_PER_REGION = {
    'Front': 4,
    'Rear': 4,
    'Side-Left': 5,
    'Side-Right': 5,
    'Front-Isometric-Left': 4,
    'Front-Isometric-Right': 4,
    'Rear-Isometric-Right': 4,
    'Rear-Isometric-Left': 4
}

export const PERFECT_ANGLE_PER_REGION = {
    'Front': 0,
    'Rear': 180,
    'Side-Left': 270,
    'Side-Right': 90,
    'Front-Isometric-Left': 315,
    'Front-Isometric-Right': 45,
    'Rear-Isometric-Right': 135,
    'Rear-Isometric-Left': 225
}
export const START_FLAG = "_START_"