import { PartDetector } from "../partdetection_new/PartDetector";

let totalImagesQueue = 0;
let imageQueue = [];
let backupImages = [];
let imageSending = false;
let imageSending2 = false;
let imageSending3 = false;
let count = 0;
let interval = null;
let interval2 = null;
let token = "";
let userEmail = "";
let location = { latitude: 0, longitude: 0 };
let inspectionId = "";
let retake = 0;
let abourt = true;
let rejectedImages = 0;
let OnceUpload = true;
let processCompleted = false;
let logBugs = true;
let imginterval;
let totalVideos = 0;
let vmgFlowType = null;
let vmgUrl = null;
let authorization = null;
let customHiibVmg = false;
let stopResizing = [false];
let setLoggingstatus = "true";
let insId = "";
let nisanCustomerEmailId = "";

let resumeFlowAllow = false;
let condition = {};
let mandatory = {};
let subMandatory = {};
let subCondition = {};
let damageCount = 0;
let windshieldDamageCount = 0;
let redisFlowSetFirstTime = false;

let partDetector = new PartDetector();
let modelPromise = {};
let modelReHitCount = 0;
let pathData = [];
let userLog = {};

export const startModel = () => {
  try {
    modelPromise = partDetector.loadModelFromURL();
  } catch (e) {
    if (modelReHitCount < 4) {
      startModel();
    }
    modelReHitCount = modelReHitCount + 1;
  }
};
export const getModel = () => {
  return modelPromise;
};

let feedbackValue = "";
export const setLogging = (v) => (setLoggingstatus = v);
export const setFeedBackStatus = (v) => (feedbackValue = v);
export const feedBackStatus = () => {
  return feedbackValue;
};
export const setInspectionID = (ins) => {
  insId = ins;
};
export const getInspectionID = () => {
  return insId;
};
export const getUserLog = () => { return userLog; };
export const setUserLogs = async (log) => (userLog = log);
export const initializeToken = (tk) => (token = tk);
export const setPathData = (data) => (pathData = data);
export const initializeEmail = (email) => (userEmail = email);
export const initializeLocation = (loc) => (location = loc);
export const initializeInspectionId = (id) => (inspectionId = id);
export const initializeretake = (rtk) => (retake = rtk);
export const setVmgFlowType = (data) => (vmgFlowType = data);
export const setVmgUrl = (data) => (vmgUrl = data);
export const setAuthorization = (data) => (authorization = data);
export const setCustomHiibVmg = (data) => (customHiibVmg = data);
export const setResizing = (data) => (stopResizing = data);
export const setConditionResume = (data) => (condition = data);
export const setMandatoryResume = (data) => (mandatory = data);
export const setSubMandatoryResume = (data) => (subMandatory = data);
export const setSubConditionResume = (data) => (subCondition = data);
export const setResumeFlowAllow = (data) => (resumeFlowAllow = data);
export const setDamageCount = (data) => (damageCount = data);
export const getDamageCount = () => {
  return damageCount;
};
export const setWindShieldDamageCount = (data) =>
  (windshieldDamageCount = data);
export const getWindshieldModuleCount = () => {
  return windshieldDamageCount;
};
export const setRedisFlowSetFirstTime = (flag) =>
  (redisFlowSetFirstTime = flag);
export const setResumeFlowCount = (data) => (count = data);

export const makeRequest = async (URL, DATA) => {
  const res = await fetch(URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    // signal: abortController,
    body: JSON.stringify(DATA),
  });
  return res;
};

export const setResumeFlowEmail = async (inspectionId, email) => {
  try {
    if (inspectionId) {
      await makeRequest(`/setResumeFlowEmail/${inspectionId}/SET`, {
        data: email,
      });
    }
  } catch (err) {
    console.log("some error occur whikle saving email  ::::::  ", err);
  }
};

export const setRedisConfigData = async (
  inspectionId,
  conditions,
  mandatoryConditions,
  subMandatoryConditions,
  subConditions
) => {
  try {
    if (inspectionId) {
      await makeRequest(`/redisConfigValue/${inspectionId}/SET`, {
        conditions: conditions,
        mandatoryConditions: mandatoryConditions,
        subMandatoryConditions: subMandatoryConditions,
        subConditions: subConditions,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const setRedisCountData = async (inspectionId, count) => {
  try {
    if (inspectionId) {
      await makeRequest(`/redisCountValue/${inspectionId}/SET`, {
        data: count,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const makeRequestVideo = async (DATA) => {
  totalImagesQueue = totalImagesQueue + 1;
  totalVideos++;
  try {
    const res = await fetch("/uploadvideo", {
      method: "POST",
      body: DATA,
    });
    if (res.ok) {
      res.json().then((res1) => {
        if (res1.status === "error") {
          totalVideos--;
        } else if (res1.status === "success") {
          totalVideos--;
        }
      });
    } else {
      totalVideos--;
    }
    return res;
  } catch (e) {
    totalVideos--;
  }
};
export const getCountData = () => {
  return count >= 1;
};

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
    img.src = src;
  });
};

export const addImageToList = async (image, conversion) => {
  if (stopResizing[0]) {
    if (Array.isArray(image)) {
      imageQueue.push(image);
    } else {
      if (image.tag === "Selfie") {
        imageQueue.push(image);
      } else {
        if (conversion === "No Conversion") {
          imageQueue.push(image);
        } else {
          //console.log(`coversion image to ${stopResizing[1]} `);
          const image1 = await loadImage(image.imgData);
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = stopResizing[1].width;
          canvas.height = stopResizing[1].height;
          context.drawImage(
            image1,
            0,
            0,
            stopResizing[1].width,
            stopResizing[1].height
          );
          let base64Data = canvas.toDataURL("image/jpeg");
          image["imgData"] = base64Data;
          imageQueue.push(image);
        }
      }
    }
  } else {
    if (Array.isArray(image)) {
      imageQueue.push(image);
    } else {
      if (image.tag === "Selfie") {
        imageQueue.push(image);
      } else {
        if (image["tag"] === "uploadImage" || conversion === "No Conversion") {
          imageQueue.push(image);
        } else {
          const image1 = await loadImage(image.imgData);
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = 1920;
          canvas.height = 1080;
          context.drawImage(image1, 0, 0, 1920, 1080);
          let base64Data = canvas.toDataURL("image/jpeg");
          image["imgData"] = base64Data;
          imageQueue.push(image);
        }
      }
    }
  }
  totalImagesQueue = totalImagesQueue + 1;
};

export const resumeFlowFinalSubmitHandler = async (currentBlock) => {
  if (Object.keys(mandatory).includes(currentBlock)) {
    mandatory[currentBlock] = "completed";
  }
  condition[currentBlock] = "completed";
  setRedisConfigData(
    inspectionId,
    condition,
    mandatory,
    subMandatory,
    subCondition
  );
};

export const imageUpload = async (inspectionId) => {
  if (logBugs) {
    imginterval = setInterval(() => {
      imgStatus(inspectionId);
      logBugs = false;
    }, 100);
  }
  if (imageQueue.length != 0 && imageSending != true) {
    imageSending = true;
    // let abortController = new AbortController();
    // clearInterval(interval)

    // interval = setInterval(() =>{
    //   abortController.abort();
    //   backupImages.push(imageQueue[0])
    //   imageQueue.splice(0, 1)
    //   imageSending = false
    //   clearInterval(interval)
    // }, 3000)

    try {
      const res = await makeRequest("/imageUpload", {
        token,
        file: imageQueue[0],
        email: userEmail,
        geolocation: location,
        count,
        inspectionId,
        vmgFlowType,
      });
      if (res.ok) {
        res.json().then((res1) => {
          if (res1.status === "error") {
            // clearInterval(interval)
            backupImages.push(imageQueue[0]);
            imageQueue.splice(0, 1);
            imageSending = false;
          } else if (res1.status === "success") {
            // clearInterval(interval)

            //setting resume flow fetching from redis
            if (resumeFlowAllow && !redisFlowSetFirstTime) {
              redisFlowSetFirstTime = true;
              makeRequest(`/fetchResumeFlow/${inspectionId}/SET`, {
                flag: true,
              });
            }

            //resume flow code
            if (resumeFlowAllow) {
              let image = imageQueue[0];
              let tag;
              let currentBlock;
              let subBlock;

              //for ZIZO
              if (Array.isArray(image)) {
                tag = image[0]["tag"];
                if (tag === "WindshieldDamage") {
                  windshieldDamageCount++;
                  makeRequest(`/windshieldRedisCount/${inspectionId}/SET`, {
                    count: windshieldDamageCount,
                  });
                } else if (tag === "Damage") {
                  damageCount++;
                  makeRequest(`/damageRedisCount/${inspectionId}/SET`, {
                    count: damageCount,
                  });
                }
              } else if (image["tag"].includes(":")) {
                //for multiple images
                tag = image["tag"].split(":");
                currentBlock = tag[0].trim();
                subBlock = tag[1].trim();
                subCondition[currentBlock][subBlock] = "completed";
                if (
                  Object.keys(subMandatory).includes(currentBlock) &&
                  Object.keys(subMandatory[currentBlock]).includes(subBlock)
                ) {
                  subMandatory[currentBlock][subBlock] = "completed";
                }
              } else {
                tag = image["tag"];
                if (Object.keys(mandatory).includes(tag)) {
                  mandatory[tag] = "completed";
                }
                condition[tag] = "completed";
              }
              setRedisConfigData(
                inspectionId,
                condition,
                mandatory,
                subMandatory,
                subCondition
              );
            }

            count++;
            imageQueue.splice(0, 1);
            imageSending = false;
            //save count for resume flow
            if (resumeFlowAllow) {
              setRedisCountData(inspectionId, count);
            }
          }
        });
      } else {
        // console.log("problem sending")
        backupImages.push(imageQueue[0]);
        imageQueue.splice(0, 1);
        imageSending = false;
      }
    } catch (err) {
      console.log(err);
      backupImages.push(imageQueue[0]);
      imageQueue.splice(0, 1);
      imageSending = false;
    }
  }
};
export const sendCoveredArea = async (
  allGreen,
  regionsMap,
  clientId,
  caseId,
  inspectionId
) => {
  let region = {};
  let region_view = {};
  let allPartsCovered = 0;
  let color = ["grey", "red", "green"];
  if (
    (clientId =
      "porto_seguros" ||
      clientId === "porto_seguros_uat" ||
      clientId === "client_834" ||
      clientId === "client_834_uat")
  ) {
    color = ["grey", "#02CCFE", "green"];
  }

  region.F = regionsMap["Front"];
  region.B = regionsMap["Rear"];
  region.LFI = regionsMap["Front-Isometric-Left"];
  region.RFI = regionsMap["Front-Isometric-Right"];
  region.LRI = regionsMap["Rear-Isometric-Left"];
  region.RRI = regionsMap["Rear-Isometric-Right"];
  region.L = regionsMap["Side-Left"];
  region.R = regionsMap["Side-Right"];

  region_view.F = color[parseInt(regionsMap["Front"])];
  region_view.B = color[parseInt(regionsMap["Rear"])];
  region_view.LFI = color[parseInt(regionsMap["Front-Isometric-Left"])];
  region_view.RFI = color[parseInt(regionsMap["Front-Isometric-Right"])];
  region_view.LRI = color[parseInt(regionsMap["Rear-Isometric-Left"])];
  region_view.RRI = color[parseInt(regionsMap["Rear-Isometric-Right"])];
  region_view.L = color[parseInt(regionsMap["Side-Left"])];
  region_view.R = color[parseInt(regionsMap["Side-Right"])];

  allPartsCovered = Object.values(color).every((value) => value === "green")
    ? 1
    : 0;

  const res = await makeRequest("/sendAreaCovered", {
    allGreen: allPartsCovered,
    region,
    region_view,
    retake,
    token,
    clientId,
    caseId,
    inspectionId,
  });
};

export const setLog = async (message) => {
  await makeRequest("/setLog", { message, inspectionId });
};

export const backupImagesStatus = () => {
  if (backupImages.length > 0 && imageQueue.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const finalUpload = async () => {
  if (imageQueue.length > 0) {
    return false;
  }
  if (backupImages.length != 0 && imageSending2 != true) {
    imageSending2 = true;
    // let abortController = new AbortController();
    // clearInterval(interval)
    // interval2 = setInterval(() =>{
    //   abortController.abort();
    //   backupImages.splice(0, 1)
    //   rejectedImages = rejectedImages + 1
    //   imageSending2 = false
    //   clearInterval(interval)
    // }, 6000)
    try {
      const res = await makeRequest("/imageUpload", {
        token,
        file: backupImages[0],
        email: userEmail,
        geolocation: location,
        count,
        inspectionId,
        vmgFlowType,
      });
      if (res.ok) {
        res.json().then((res1) => {
          if (res1.status === "error") {
            backupImages.splice(0, 1);
            imageSending2 = false;
          } else if (res1.status === "success") {
            // clearInterval(interval)
            count++;
            backupImages.splice(0, 1);
            imageSending2 = false;
          }
        });
      } else {
        backupImages.splice(0, 1);
        imageSending2 = false;
      }
    } catch (err) {
      console.log(err.message, err.name);
      backupImages.splice(0, 1);
      // rejectedImages = rejectedImages + 1
      imageSending2 = false;
    }
  }
  if (backupImages.length > 0) {
    return false;
  }

  try {
    if (!imageSending3) {
      imageSending3 = true;
      clearInterval(imginterval);
      const res = await makeRequest("/finalUpload", {
        count: count,
        inspectionId,
        email: userEmail,
        token,
        geolocation: location,
        vmgFlowType,
        vmgUrl,
        authorization,
        customHiibVmg,
      });
      if (res.ok) {
        let message = `(Total Images${totalImagesQueue - totalVideos})`;
        userLogs({ position: 5, fInal_image_upload: "yes", inspectionId });
        await makeRequest("/setLog", { message, inspectionId });
        let val = await res.json();
        if (val.status === "usedToken") {
          return "usedToken";
        } else {
          return true;
        }
      }
      userLogs({ position: 5, fInal_image_upload: "no", inspectionId });
      imageSending3 = false;
      return false;
    }
  } catch (err) {
    userLogs({ position: 5, fInal_image_upload: "no", inspectionId });
    imageSending3 = false;
    return false;
  }
};

export const totalImagesUploadStatus = () => {
  // console.log({
  //   uploadedImage:
  //     totalImagesQueue -
  //     (imageQueue.length + backupImages.length + totalVideos),
  //   totalImage: totalImagesQueue,
  // });
  return {
    uploadedImage:
      totalImagesQueue -
      (imageQueue.length + backupImages.length + totalVideos),
    totalImage: totalImagesQueue,
  };
};

export const QRDataUpload = async (data) => {
  await makeRequest("/qrData", { inspectionId, data, token });
};

export const formUpload = async (jsonFileWithDocuments, jsonFile, impData) => {
  let data = {
    jsonFileWithDocuments,
    jsonFile,
    impData,
  };

  await makeRequest("/sendForm", { inspectionId, token, data });
};
export const pdfUpload = async (clientId, inspectionId, data) => {
  await makeRequest("/pdfupload", { clientId, inspectionId, data });
};
export const actualRegion = async (data, inspection_id) => {
  await makeRequest("/actualregion", { data, inspection_id });
};

export const vinStatus = async () => {
  try {
    const response = await fetch("/vinstatus");
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const ping = async () => {
  const res = await fetch("/ping");
  return res.ok;
};

export const lowLightImage = async () => {
  try {
    const response = await fetch("/lowLightImage");
    const result = await response.json();
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};
export const userLogs = async (data, log, flag) => {
  if (setLoggingstatus === "true") {
    try {
      await fetch(process.env.REACT_APP_LAMBDA_LOG_URL, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
    }
    catch (err) { console.log("mysql log sending error") }
  }
  try {
    if (flag && setLoggingstatus) {
      await setUserLogs(log);
      userLog = log;
      //console.log("efefrefrf        ", userLog)
      await makeRequest('/saveLogS3', {
        inspectionId: log.inspection_id,
        data: log
      });
    }
    else if (setLoggingstatus) {
      //console.log("log:: ", data, data.pos, userLog)
      // let res = await makeRequest('/getLogS3', {
      //   inspectionId: data.inspectionId
      // });

      if (data.position === 1) {
        userLog["camera_permission"] = data.camera_permission;
        userLog["gyro_permission"] = data.gyro_permission;
        userLog["location_permission"] = data.location_permission;
        userLog["desktop_view"] = data.desktop_view;
      }
      else if (data.position === 2) {
        userLog["image_upload_status"] = data.image_upload_status;
      }
      else if (data.position === 3) {
        userLog["video_data"] = data.video_data;
      }
      else if (data.position === 5) {
        userLog["final_image_Upload"] = data.fInal_image_upload;
      }
      else if (data.position === 6) {
        userLog["model_initialized"] = data.model_initialized;
      }
      else if (data.position === 7) {
        userLog["user_step_covered"] = data.user_step_covered;
      }
      else if (data.position === 8) {
        userLog["last_page"] = data.last_page;
      }
      else if (data.position === 9) {
        userLog["record_button"] = data.record_button;
      }
      else if (data.position === 12) {
        userLog["loading_timer"] = data.loading_timer;
      }
      else if (data.position === 13) {
        userLog["complete_module"] = data.completed_module;
      }
      //console.log("sending log :: ", userLog)
      await makeRequest('/saveLogS3', {
        inspectionId: data.inspectionId,
        data: userLog
      });
      // if (res.ok) {
      //   let result = await res.json();
      //   // console.log("result ::   ", result)
      //   let log = result.body;

      // }
    }
  }
  catch (err) {
    console.log("error while saving log to s3 ::: ", err);
  }
};


export const rejectionBatch = async (data) => {
  try {
    const response = await makeRequest("/rejectInspection", data);
    const result = await response.json();
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};

export const addUserAgentData = async (data) => {
  try {
    const response = await makeRequest("/addUserAgentData", data);
    const result = await response.json();
    return result["status"];
  } catch (error) {
    console.log(error);
  }
};

export const gethiibMssg = async (data) => {
  try {
    const response = await makeRequest("/gethiibMssg", data);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const vin_detection = async (data) => {
  try {
    const response = await makeRequest("/vinDetection", data);
    const result = await response.json();
    // console.log("``````VIN``````", typeof result.status, result.status);
    return result.status;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const imageFeedbackLambda = async (data) => {
  try {
    const response = await makeRequest("/imageFeedback", data);
    const result = await response.json();
    return result.status;
  } catch (error) {
    console.log(error);
    return {
      feedback: "",
    };
  }
};

let datas;
let values = "";
const imgStatus = async (inspectionId) => {
  datas = `Images uploading not including final Upload image  ${totalImagesQueue - (imageQueue.length + backupImages.length)
    } / ${totalImagesQueue}`;
  if (values !== datas) {
    values = `Images uploading not including final Upload image  ${totalImagesQueue - (imageQueue.length + backupImages.length)
      } / ${totalImagesQueue}`;
    userLogs({
      position: 2,
      image_upload_status: `${totalImagesQueue - (imageQueue.length + backupImages.length)
        } / ${totalImagesQueue}`,
      inspectionId,
    });
  }
};

export const patLogs = async (data, inspe) => {
  let inspet;
  if (!inspe) inspet = insId
  else inspet = inspe
  pathData.push(data);
  await userLogs({
    position: 7,
    user_step_covered: pathData,
    inspectionId: inspet
  });
  await makeRequest(`/setUserStepCount/${inspet}/SET`, { path: pathData.join(', ') });
};

export const mlLogs = async (data) => {
  await patLogs(data, inspectionId);
};

export const setRating = async (data) => {
  try {
    await makeRequest("/setRating", data);
  } catch (err) {
    console.log(err);
  }
};

export const setImgCOunt = async (inspectionId, imgValue) => {
  try {
    await makeRequest("/imgCount", { inspectionId, imgValue });
  } catch (err) {
    console.log(err);
  }
};
